h3 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

p {
  margin-bottom: 1rem;
}
